import {CountUp} from "./plugins/countUp.js";

document.addEventListener("DOMContentLoaded", function () {
    AOS.init({
        startEvent: 'load',
        offset: 150,
        delay: 0,
        duration: 700,
        easing: "ease",
        once: true,
    });

    const headerElem = document.querySelector(".header");

    // countUp
    let countUpArr = [];

    function CountUpFunction(id, finalNum, option = {}) {
        let defaultOptions = {
            separator: "",
            enableScrollSpy: true,
            scrollSpyRunOnce: true,
        };

        let mergeOptions = {...defaultOptions, ...option};

        const countItem = new CountUp(id, finalNum, mergeOptions);

        new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) countItem.start()
            })
        }).observe(id)

        countUpArr.push(countItem);
    }

    const counters = document.querySelectorAll(".results__count");

    counters.forEach((counter, i) => {
        const value = counter.getAttribute("data-count");
        CountUpFunction(counter, value, {suffix: ""});
    });
    // end countUp

    // fix ios height
    if (innerWidth < 1024) {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        window.addEventListener("resize", () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        });
    }
    // end fix ios height

    // burger-menu
    const burgerMenu = (menuSelector, burgerSelector) => {
        const menuElem = document.querySelector(menuSelector),
            layoutElem = document.querySelector(".header__layout"),
            burgerElem = document.querySelector(burgerSelector);

        const removeClass = () => {
            menuElem.classList.remove("active");
        };
        removeClass();

        menuElem.querySelectorAll("a").forEach(i => {
            i.addEventListener("click", e => {
                if (menuElem.classList.contains("active")) {
                    removeClass();
                }
            });
        });

        burgerElem.addEventListener("click", () => {

            if (menuElem.classList.contains("active")) {
                removeClass();
                scrollLock.enablePageScroll()
            } else {
                menuElem.classList.add("active");
                scrollLock.disablePageScroll(document.querySelector('.header__simplebar .simplebar-content-wrapper'))
            }
        });

        window.addEventListener("resize", () => {
            if (innerWidth > 1023) {
                removeClass();
            }
        });

        layoutElem.addEventListener("click", () => {
            removeClass();
        });

    };

    if (document.querySelector(".header")) {
        burgerMenu(".header", ".header__burger");
    }
    // end burger-menu

    // sticky
    function stickyMenu() {
        if (window.pageYOffset >= 10) {
            headerElem.classList.add("sticky");
        } else {
            headerElem.classList.remove("sticky");
        }
    }

    window.addEventListener("scroll", stickyMenu);
    stickyMenu();
    // end sticky

    // scroll progress animation
    function scrollProgress(section, startIndex = 0.25) {
        let sectionTopPosition = section.offsetTop - window.innerHeight + window.innerHeight * startIndex;
        const windowScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const sectionHeight = section.scrollHeight;
        const scrolled = ((windowScroll - sectionTopPosition) / sectionHeight) * 100;

        return scrolled
    }

    const teamSection = document.querySelector(".team");
    if (teamSection) {
        const decor = teamSection.querySelector(".team__decor");

        window.onscroll = () => {
            let scrolled = scrollProgress(teamSection)

            if (scrolled > 0) {
                decor.classList.add("active")
            } else {
                decor.classList.remove("active")
            }

            decor.style.right = scrolled + "%";
        }
    }
    // end scroll progress animation

    // scroll to job results
    window.scrollToResults = function () {
        const jobsResult = document.querySelector(".job-board");
        if (jobsResult) {
            let topOffset = jobsResult.offsetTop - headerElem.clientHeight

            if (window.innerWidth <= 901) {
                const jobsFilters = document.querySelector(".job-board__filters");
                topOffset += jobsFilters.scrollHeight - headerElem.clientHeight
            }

            window.scrollTo({
                top: topOffset,
                behavior: 'smooth'
            });
        }
    }

    // choices
    const selectList = document.querySelectorAll(".choice-item");
    if (selectList.length) {
        selectList.forEach(select => {
            new Choices(select, {
                searchEnabled: false
            })
        })
    }
    // end choices

    // range slider
    const doubleRange = document.getElementsByClassName(
        "double-range-tooltips"
    )[0];
    if (doubleRange) {
        const slider = doubleRange.querySelector("#double-range-tooltips");
        const max = +slider.dataset.max;
        const min = +slider.dataset.min;
        const unit = slider.dataset?.unit || "£";
        const step = +slider.dataset.step;
        const inputsHidden = doubleRange.querySelectorAll(
            ".double-range-hidden-input"
        );
        const startValueMin = +inputsHidden[0].value;
        const startValueMax = +inputsHidden[1].value;

        // how many percentages limit from borders ???? is 8%
        const borderLimit = 8;

        // each step is go backward for this amount of % ???? is 5%
        const borderDiff = 40 / borderLimit;

        noUiSlider.create(slider, {
            start: [startValueMin, startValueMax],
            connect: true,
            tooltips: true,
            margin: 10,
            step: step,
            range: {
                min: min,
                max: max,
            },
        });

        const tooltipsArr = slider.querySelectorAll(".noUi-tooltip");
        const circlesArr = slider.querySelectorAll(".noUi-origin");

        function returnTransform(element) {
            return element
                .replace(/[^0-9][^\d.]/g, "")
                .replace(")", "")
                .split(" ")
                .map((str) => {
                    return Number(str);
                });
        }

        function needToMerge() {
            let tooltipOnePosition = tooltipsArr[0].getBoundingClientRect();
            let tooltipTwoPosition = tooltipsArr[1].getBoundingClientRect();

            if (
                tooltipsArr[0].classList.contains("hidden") ||
                tooltipsArr[1].classList.contains("hidden")
            ) {
                return true;
            }

            return (
                tooltipOnePosition.left +
                tooltipOnePosition.width -
                tooltipTwoPosition.left >
                0
            );
        }

        function resetTooltips(values) {
            mergeDiff = null;
            tooltipsArr.forEach((elem, index) => {
                elem.textContent =
                    Math.round(values[index]).toLocaleString("en-GB").replace(",", "") + unit;
                elem.classList.remove("hidden");
            });
        }

        let trueTooltip = false;
        let mergeDiff = null;

        slider.noUiSlider.on("update", function (values, handle) {
            // translate of noUISlider -> 0% is start, 100% is end
            let translate = returnTransform(circlesArr[handle].style.transform)[0];

            // min value of double range slider
            let valueMin = returnTransform(circlesArr[0].style.transform)[0];

            // max value of double range slider
            let valueMax = returnTransform(circlesArr[1].style.transform)[0];

            // difference between min and max value of double range slider
            let difference = valueMax - valueMin;

            inputsHidden[handle].value = Math.round(values[handle]);
            // inputsHidden[handle].onchange();

            // if tooltips are close to each other
            if (needToMerge()) {
                if (
                    !tooltipsArr[+!handle].classList.contains("hidden") &&
                    !tooltipsArr[handle].classList.contains("hidden")
                ) {
                    trueTooltip = handle;
                    mergeDiff = difference;
                    tooltipsArr[+!handle].classList.add("hidden");
                }

                if (trueTooltip) {
                    // limit left merged tooltip from overflowing
                    // borderLimit * 3 === need for 3 times faster limitation because of merged tooltip
                    if (translate <= -100 + borderLimit * 3) {
                        tooltipsArr[trueTooltip].style.transform = `translate(${
                            -50 -
                            difference +
                            (Math.abs(translate + 100 - borderLimit * 3) * borderDiff) / 2.5
                        }%,100%)`;
                    } else {
                        // position of tooltip in the middle of range
                        tooltipsArr[trueTooltip].style.transform = `translate(${
                            -50 - difference
                        }%,100%)`;
                    }
                } else {
                    // if left tooltip is grabbed
                    if (translate >= -borderLimit * 4) {
                        // limit right merged tooltip from overflowing
                        tooltipsArr[trueTooltip].style.transform = `translate(${
                            -50 +
                            difference -
                            (Math.abs(translate + borderLimit * 3) * borderDiff) / 2.5
                        }%,100%)`;
                    } else {
                        tooltipsArr[trueTooltip].style.transform = `translate(${
                            -50 + difference
                        }%,100%)`;
                    }
                }

                tooltipsArr[trueTooltip].textContent = `${
                    Math.round(values[0]).toLocaleString("en-GB").replace(",", "") + unit
				} - ${Math.round(values[1]).toLocaleString("en-GB").replace(",", "") + unit}`;

                if (mergeDiff - difference < 0) {
                    mergeDiff = null;
                    resetTooltips(values);
                }
            } else {
                // limit left solo tooltip from overflowing
                if (translate <= -100 + borderLimit) {
                    tooltipsArr[0].style.transform = `translate(${
                        -50 + Math.abs(translate + 100 - borderLimit) * borderDiff
                    }%,100%)`;
                } else if (translate >= -borderLimit) {
                    // limit right solo tooltip from overflowing
                    tooltipsArr[1].style.transform = `translate(${
                        -50 - Math.abs(translate + borderLimit) * borderDiff
                    }%,100%)`;
                } else {
                    // position of tooltip in the middle of range
                    tooltipsArr[handle].style.transform = "translate(-50%,100%)";
                }

                tooltipsArr[handle].textContent = `${
                    Math.round(values[handle]).toLocaleString("en-GB").replace(",", "")
                } ${unit}`;
            }
        });

        slider.noUiSlider.on("change", _.debounce(() => {
            load('jobs/search', 'form:#search_form', 'form:#search_form2')
        }, 200))
    }
	// end range slider

	// filters accordion
	const filterBoxes = document.querySelectorAll(".filter-box");
	if (filterBoxes.length) {
        function toggleVisibility(box) {
            const boxContent = box.querySelector(".filter-box__content");

            if (box.classList.contains("open")) {
                boxContent.style.height = boxContent.scrollHeight + "px"
            } else {
                boxContent.style.height = ""
            }
        }

        filterBoxes[0].classList.add("open");

        toggleVisibility(filterBoxes[0])

		filterBoxes.forEach(box => {
            const headTrigger = box.querySelector(".filter-box__head");

            headTrigger.addEventListener("click", () => {
                box.classList.toggle("open");

                toggleVisibility(box)
            })
		})
	}

    // simplebar
    if (document.querySelector(".header__simplebar") && innerWidth < 1024) {
        new SimpleBar(document.querySelector(".header__simplebar"));
    }

    // services slider
    if (innerWidth < 1024) {
        if (document.querySelector(".services__slider--main")) {
            new Swiper(`.services__slider--main`, {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 40,
                grid: {
                    rows: 2,
                },
                speed: 400,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                breakpoints: {
                    569: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        grid: {
                            rows: 2,
                        },
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                        grid: {
                            rows: 2,
                        },
                    },
                },
                navigation: {
                    nextEl: ".services__next",
                    prevEl: ".services__prev",
                },
            });
        } else {
            new Swiper(`.services__slider--about`, {
                loop: false,
                slidesPerView: 1,
                spaceBetween: 40,

                speed: 400,

                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },

                breakpoints: {
                    551: {
                        slidesPerView: 2,
                    },

                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                },

                navigation: {
                    nextEl: ".services__next",
                    prevEl: ".services__prev",
                },
            });
        }
    }

    if (document.querySelector(".jobseekers__slider") && innerWidth < 1024) {
        new Swiper(`.jobseekers__slider`, {
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 400,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                569: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            },
            navigation: {
                nextEl: ".jobseekers__next",
                prevEl: ".jobseekers__prev",
            },
        });
    }

    if (document.querySelector(".team__slider")) {
        new Swiper(`.team__slider`, {
            slidesPerView: 1,
            spaceBetween: 40,
            // speed: 400,

            autoplay: {
            	delay: 3000,
            	disableOnInteraction: false,
            	pauseOnMouseEnter: true,
            },

            breakpoints: {
                569: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            },
            navigation: {
                nextEl: ".team__next",
                prevEl: ".team__prev",
            },
        });
    }
    if (document.querySelector(".testimonials__slider")) {
        new Swiper(`.testimonials__slider`, {
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 400,
            autoplay: {
            	delay: 3000,
            	disableOnInteraction: false,
            	pauseOnMouseEnter: true,
            },

            breakpoints: {
                569: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                },
            },
            navigation: {
                nextEl: ".testimonials__next",
                prevEl: ".testimonials__prev",
            },
        });
    }

    if (document.querySelector(".clients__slider")) {
        new Swiper(`.clients__slider`, {
            slidesPerView: 2,
            spaceBetween: 40,
            speed: 400,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },

            breakpoints: {
                460: {
                    spaceBetween: 80,
                },
                568: {
                    spaceBetween: 80,
                    slidesPerView: 3,
                },
                1023: {
                    spaceBetween: 80,
                    slidesPerView: 5,
                },
            },
            navigation: {
                nextEl: ".clients__next",
                prevEl: ".clients__prev",
            },
        });
    }

    if (document.querySelector(".jobs__slider")) {
        new Swiper(`.jobs__slider`, {
            slidesPerView: 1,
            spaceBetween: 10,
            speed: 400,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                569: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            },
            navigation: {
                nextEl: ".jobs__next",
                prevEl: ".jobs__prev",
            },
        });
    }

    if (document.querySelector(".values__slider") && innerWidth < 1024) {
        new Swiper(`.values__slider`, {
            slidesPerView: 1,
            spaceBetween: 40,
            speed: 400,
            autoplay: {
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            breakpoints: {
                569: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
            },
            navigation: {
                nextEl: ".values__next",
                prevEl: ".values__prev",
            },
        });
    }
});
